import React, { useState } from "react";
import "../styles/AboutSection.css";
import NewsletterPopup from "../pages/Newsletter";

const AboutSection = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleOpenPopup = () => setIsPopupOpen(true);
    const handleClosePopup = () => setIsPopupOpen(false);

    return (
        <section className="about-section">
        {/* Heading */}
        <h2 className="heading">Über Skilt</h2>
        <div className="divider"></div>

        {/* Text Content */}
        <div className="text-box">
            <p className="text">
                Skilt macht theoretische Inhalte der Handwerks-Ausbildung verständlich 
                und zugänglich.
            </p>
            <p className="text">
                Durch kompakte Wissenseinheiten und interaktive Quizzes erleichtert die 
                App das Lernen und passt sich den Anforderungen der digitalen Generation an.
            </p>
            <p className="text">
                Die App richtet sich an Auszubildende im Beruf Anlagenmechaniker*in SHK 
                und wird künftig auch für weitere Handwerksberufe verfügbar sein.
            </p>
            <p className="text">
                Du möchtest ein gemeinsames Projekt mit uns starten oder hast eine Idee 
                für eine Zusammenarbeit? Schreib uns gerne unter 
                <strong> info@skilt.app</strong>.
            </p>
        </div>

        {/* Newsletter Popup entfernt */}
        {/* {isPopupOpen && <NewsletterPopup onClose={handleClosePopup} />} */}
    </section>
    );
};

export default AboutSection;
