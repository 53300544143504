import React from "react";
import "../styles/SecondSection.css";

const SecondSection = () => {
    return (
    <section className="second-section">
    {/* Text Content */}
    <div className="text-box">
        <h2 className="heading">Deine Lern-App fürs SHK-Handwerk</h2>
        <p className="text">
        Skilt macht die theoretischen Inhalte aus deiner Ausbildung zur <strong>Anlagenmechaniker*in</strong> einfach zugänglich – wie du sie aus dem Buch kennst, aber im App-Format.
        </p>
        <p className="text">
        Über <strong>1200 kurze Lerneinheiten</strong> erklären die Inhalte verständlich und praxisnah – klar gegliedert nach Lehrjahren und Lernfeldern.
        </p>
        <p className="text">
        <strong>Quizfragen</strong> zwischen den Einheiten helfen dir dabei, das Gelernte direkt anzuwenden und zu festigen.
        </p>
        <p className="text">
        Alle Inhalte sind <strong>offline</strong> verfügbar, sodass du jederzeit und überall lernen kannst.
        </p>
    </div>

    {/* Image Content */}
    <div className="image-box">
        <img
        src="/images/website_section_2.png"
        alt="Zwei Smartphones mit der Skilt Lern-App für die SHK-Ausbildung. Auf dem ersten Bildschirm sind die Lernfelder für Anlagenmechaniker*innen SHK zu sehen, auf dem zweiten die dazugehörigen Kapitel – perfekt strukturiert für Auszubildende"
        className="image"
        />
    </div>
    </section>

    );
};

export default SecondSection;
