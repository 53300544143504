import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/Footer.css";
import { FaInstagram } from "react-icons/fa";

// import NewsletterPopup from "../pages/Newsletter"; // Newsletter auskommentiert

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleStartClick = () => {
        if (location.pathname === "/") {
            // Use a ref or scroll directly to the StartSection if on the main page
            const startSection = document.querySelector(".start-section");
            if (startSection) {
                startSection.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            // Navigate to the main page and then scroll to the StartSection
            navigate("/", { replace: true });
        }
    };

    return (
        <footer className="footer">
            <nav>
                <button onClick={handleStartClick} className="footer-link">
                    Start
                </button>

                <a href="mailto:info@skilt.app" className="footer-link">
                    Kontakt
                </a>

                <a href="/impressum" className="footer-link">
                    Impressum
                </a>

                <a href="/agb" className="footer-link">
                    AGBs
                </a>

                <a
                    href="https://www.instagram.com/skilt.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="footer-link"
                    aria-label="Instagram"
                >
                    <FaInstagram size={22} />
                </a>
            </nav>

            {/* Newsletter Popup entfernt */}
            {/* {isPopupOpen && <NewsletterPopup onClose={handleCloseNewsletter} />} */}
        </footer>
    );
};

export default Footer;
